<template>
  <b-modal
    id="modal-comment"
    :title="modalTitle"
    centered
    no-close-on-backdrop
    hide-footer
    size="xl"
    @hidden="resetModal"
    @show="onShowForm"
    @close="onCloseForm"
  >
    <b-overlay :show="loading">
      <form @submit.prevent="handleValidate">
        <b-row>
          <b-col class="pr-7" :class="form.type && 'border-right'">
            <div v-if="customerInfo" class="pb-2">
              <div class="infomation">
                <avatar
                  size="60px"
                  :text="customerInfo.fullName"
                  rounded
                  :src="customerInfo.imageUrl.url"
                />
                  
                <div>
                  <div class="font-weight-bolder">
                    {{ customerInfo.fullName }}
                  </div>
                  <div class="text-gray">
                    {{
                      customerInfo.ownPackage
                        ? customerInfo.ownPackage.name
                        : 'free'
                    }}
                  </div>
                </div>
              </div>
              <b-row>
                <b-col>
                  <div class="text-gray">Năm sinh:</div>
                  <div class="font-weight-bolder">
                    {{ new Date().getFullYear() - customerInfo.age }}
                  </div>
                </b-col>
                <b-col>
                  <div class="text-gray">Giới tính:</div>
                  <div class="font-weight-bolder">
                    {{ customerInfo.gender }}
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <div class="text-gray">Tình trạng:</div>
                  <div class="font-weight-bolder">
                    {{ customerInfo.diabetes.name }}
                  </div>
                </b-col>
                <b-col>
                  <div class="text-gray">Thời gian có bệnh:</div>
                  <div class="font-weight-bolder">
                    {{ getDurationMonth(customerInfo.diabetes.date) }}
                  </div>
                </b-col>
              </b-row>
            </div>
            <!-- <basic-input
              label="Người nhận xét"
              required
              disabled
              :value.sync="currentUser.fullName"
            />
            <div>
              <label for="date">Ngày nhận xét</label>
              <basic-date-picker
                id="date"
                required
                disabled
                v-model="form.commentDate"
                type="date"
                format="DD/MM/YYYY"
                value-type="format"
              />
            </div> -->
            <basic-select
              class="pl-2"
              label="Loại đánh giá"
              placeholder="--- Chọn loại đánh giá ---"
              required
              :value.sync="form.type"
              changeValueByObject
              :options="types"
              track-by="value"
              value-label="text"
              :allow-empty="true"
              :disabled="!isCreate"
              :state="validateState('type')"
              :invalidFeedback="errors.first('type')"
              v-validate="'required'"
              name="type"
              data-vv-as="Loại đánh giá"
              @update:value="selectRatingType"
            />
            <template v-if="!isRatingDoctor">
              <basic-select
                class="pl-2"
                label="Tiến độ học"
                placeholder="--- Chọn tiến độ học ---"
                required
                changeValueByObject
                :options="studyProgress"
                :value.sync="form.progressType"
                track-by="value"
                value-label="text"
                :disabled="isDetail"
                :state="validateState('progress')"
                :invalidFeedback="errors.first('progress')"
                v-validate="'required'"
                name="progress"
                data-vv-as="Tiến độ học"
                @update:value="$validator.reset({ name: 'progress' })"
              />
              <basic-select
                class="pl-2"
                label="Ưu tiên hỗ trợ"
                placeholder="--- Chọn ưu tiên hỗ trợ ---"
                required
                :options="prioritySupport"
                changeValueByObject
                :value.sync="form.supportType"
                track-by="value"
                value-label="text"
                :disabled="isDetail"
                :state="validateState('support')"
                :invalidFeedback="errors.first('support')"
                v-validate="'required'"
                name="support"
                data-vv-as="Ưu tiên hỗ trợ"
                @update:value="$validator.reset({ name: 'support' })"
              />
            </template>
            <div class="row" >
 
 
            <b-form-group
              label="HbA1c (%)"
              v-if="isInputCoach || isOutputCoach"
              class="pl-7"
            >
              <b-form-input
                :state="validateState('HBAC')"
                placeholder="Nhập chỉ số"
                maxlength="3"
                name="HBAC"
                :disabled="isDetail"
                v-model="form.HBAC"
                v-b-tooltip="'Không cần nhập đơn vị % sau giá trị'"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Chiều cao (cm)"
              v-if="isInputCoach || isOutputCoach"
              class="pl-7"
            >
              <b-form-input
                :state="validateState('chieucao')"
                placeholder="Nhập chiều cao"
                maxlength="3"
                name="chieucao"
                v-model="form.chieucao"
                @input="calculateBMI"
                :disabled="isDetail"
                v-b-tooltip="'Không cần nhập đơn vị cm sau giá trị'"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label="Cân nặng (kg)"
              v-if="isInputCoach || isOutputCoach"
              class="pl-7"
            >
              <b-form-input
                :state="validateState('cannang')"
                placeholder="Nhập cân nặng"
                maxlength="3"
                name="cannang"
                v-model="form.cannang"
                @input="calculateBMI"
                :disabled="isDetail"
                v-b-tooltip="'Không cần nhập đơn vị kg sau giá trị'"
              ></b-form-input>
              
            </b-form-group>
              </div>
              <div class="row">
            <b-form-group
              label="BMI"
              v-if="isInputCoach || isOutputCoach"
              class="pl-7"
            >
              <b-form-input
                :state="validateState('BMI')"
                :disabled="true"
                placeholder="BMI"
                maxlength="4"
                name="BMI"
                v-model="this.form.BMI"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label="Phân loại kiến thức"
              v-if="isInputCoach || isOutputCoach"
              class="pl-7"
            >
              <b-form-input
                :state="validateState('PointKT')"
                placeholder="Phân loại kiến thức"
                maxlength="3"
                name="PointKT"
                v-model="form.PointKT"
                :disabled="isDetail"
              ></b-form-input>
            
            </b-form-group>
            <b-form-group
              label="Khả năng tự chăm sóc"
              v-if="isInputCoach || isOutputCoach"
              class="pl-7"
            >
              <b-form-input
                :state="validateState('PointVD')"
                placeholder="Khả năng tự chăm sóc"
                maxlength="2"
                name="PointVD"
                :disabled="isDetail"
                v-model="form.PointVD"
              ></b-form-input>
            </b-form-group>
              </div>
          </b-col>
          
          <b-col v-if="form.type" class="pl-7">
            <basic-select
              v-if="!isRatingDoctor"
              label="Buổi coach"
              placeholder="--- Chọn buổi coach ---"
              required
              :options="coachSections"
              changeValueByObject
              :value.sync="form.coachSection"
              track-by="value"
              value-label="text"
              :invalid-feedback="errors.first('coachSection')"
              :state="validateState('coachSection')"
              name="coachSection"
              v-validate="'required'"
              data-vv-as="Buổi coach"
              :disabled="!isCreate"
              @update:value="$validator.reset({ name: 'coachSection' })"
            />

            <template v-if="isInputCoach || isOutputCoach">
          
              <basic-text-editors
                v-if="isInputCoach"
                label="Mục tiêu chương trình"
                 required
                placeholder="Quá trình bị bệnh? Cuộc sống với bênh ĐTĐ hiện tại như thế nào?"
                :state="validateState('story')"
                :invalidFeedback="errors.first('story')"
                v-validate="{
                  required: true,
                  max: 1000,
                }"
                name="story"
                data-vv-as="Mục tiêu chương trình"
                :value.sync="form.clientStory"
                :disabled="isDetail"
                maxlength="1000"
              >
              </basic-text-editors>
              <template v-if="isInputCoach">
                <!-- <basic-text-area -->
                <basic-text-editors
                  label="Mục tiêu thay đổi lối sống"
                  required
                  placeholder="Mục tiêu thay đổi lối sống"
                  maxlength="1000"
                  :value.sync="form.target"
                  :state="validateState('target')"
                  :invalidFeedback="errors.first('target')"
                  v-validate="{
                    required: true,
                    max: 1000,
                  }"
                  v-b-tooltip="'Xóa những dòng không điền'"
                  name="target"
                  :disabled="isDetail"
                  data-vv-as="Mục tiêu thay đổi lối sống"
                >
                </basic-text-editors>
                <!-- <basic-text-area -->
                <!-- <basic-text-editors
                  label="Kế hoạch hành động"
                  required
                  placeholder="Kế hoạch hành động"
                  maxlength="1000"
                  :value.sync="form.plan"
                  :state="validateState('plan')"
                  :invalidFeedback="errors.first('plan')"
                  v-validate="{
                   required: true,
                    max: 1000,
                  }"
                   v-b-tooltip="'Điền tương ứng với từng mục tiêu theo số thứ tự'"
                  name="plan"
                  :disabled="isDetail"
                  data-vv-as="Kế hoạch hành động"
                /> -->
                <basic-text-editors
                  label="Trở ngại thực hiện mục tiêu"
                 
                  placeholder="Trở ngại thực hiện mục tiêu"
                  maxlength="1000"
                  :value.sync="form.trouble"
                  :state="validateState('trouble')"
                  :invalidFeedback="errors.first('trouble')"
                  v-validate="{
                //    required: true,
                    max: 1000,
                  }"
                  name="trouble"
                  :disabled="isDetail"
                  data-vv-as="Trở ngại thực hiện mục tiêu"
                />
              </template>
            </template>
            <div v-if="isCoach1_1">
              <basic-text-editors
                label="Hoạt động tiếp theo"
                placeholder="Nhập hành động tiếp theo"
                name="nextActivity"
                data-vv-as="Hoạt động tiếp theo"
                v-validate="'required'"
                :required="true"
                :state="validateState('nextActivity')"
                :invalidFeedback="errors.first('nextActivity')"
                v-model="form.nextActivity"
                :disabled="isDetail"
              ></basic-text-editors>
            </div>
            <basic-text-area
              v-if="isRatingDoctor"
              label="Ghi chú cho diaB"
              required
              placeholder="Ghi chú cho diaB"
              :value.sync="form.note"
              :disabled="isDetail"
              maxlength="1000"
              :state="validateState('note')"
              :invalidFeedback="errors.first('note')"
              v-validate="{
                required: true,
                max: 1000,
              }"
              name="note"
              data-vv-as="Ghi chú cho diaB"
            />
            <basic-text-editors
              class="ql-container.ql-snow"
              v-if="!isOutputCoach"
              label="Nhận xét cho khách hàng"
              placeholder="Nhận xét cho khách hàng"
              name="comment"
              data-vv-as="Nhận xét cho khách hàng"
              v-validate="'required'"
              :required="true"
              :state="validateState('comment')"
              :invalidFeedback="errors.first('comment')"
              :value.sync="form.comment"
              :disabled="isDetail"
            >
            </basic-text-editors>

            <template v-if="isCoach1_1">
              <v-checkbox
                v-model="form.fitnessTest"
                label="Kết quả kiểm tra thể lực"
                color="#4BB2AB"
                :disabled="isDetail"
              ></v-checkbox>
              <template v-if="form.fitnessTest">
                <basic-input
                  label="Thăng bằng trái"
                  required
                  placeholder="Thăng bằng trái"
                  input-number
                  name="guyLeft"
                  data-vv-as="Thăng bằng trái"
                  v-validate="'required'"
                  :state="validateState('guyLeft')"
                  :invalidFeedback="errors.first('guyLeft')"
                  :value.sync="form.guyLeft"
                  :disabled="isDetail"
                />
                <basic-input
                  label="Thăng bằng phải"
                  required
                  placeholder="Thăng bằng phải"
                  input-number
                  name="guyRight"
                  data-vv-as="Thăng bằng phải"
                  v-validate="'required'"
                  :state="validateState('guyRight')"
                  :invalidFeedback="errors.first('right')"
                  :value.sync="form.guyRight"
                  :disabled="isDetail"
                />
                <basic-input
                  label="Đứng lên, ngồi xuống"
                  required
                  placeholder="Đứng lên, ngồi xuống"
                  input-number
                  data-vv-as="Đứng lên, ngồi xuống"
                  name="sitDownAndStandUp"
                  v-validate="'required'"
                  :state="validateState('sitDownAndStandUp')"
                  :invalidFeedback="errors.first('sitDownAndStandUp')"
                  :value.sync="form.sitDownAndStandUp"
                  :disabled="isDetail"
                />
              </template>
            </template>
            <div v-if="isOutputCoach">
              <basic-text-editors
                label="Đánh giá cuối khóa cho khách hàng"
                placeholder="Đánh giá cuối khóa cho khách hàng"
                name="ratingForClient"
                data-vv-as="Đánh giá cuối khóa cho khách hàng"
                v-validate="{
                  required: true,
                }"
                required
                :state="validateState('ratingForClient')"
                :invalidFeedback="errors.first('ratingForClient')"
                :value.sync="form.ratingForClient"
                :disabled="isDetail"
              ></basic-text-editors>
              <basic-select
                label="Kết luận"
                required
                placeholder="--- Chọn kết luận ---"
                :options="summaryTypes"
                changeValueByObject
                :state="validateState('summary')"
                :invalidFeedback="errors.first('summary')"
                v-validate="{
                  required: true,
                }"
                :value.sync="form.conclusion"
                name="summary"
                data-vv-as="Kết luận"
                track-by="value"
                value-label="text"
                :disabled="isDetail"
              />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!isDetail">
          <b-col>
            <div class="d-flex justify-content-end align-items-center mt-4">
              <b-button
                class="btn mr-2"
                type="button"
                @click="onClickCancelBtn"
              >
                Hủy
              </b-button>
              <b-button class="btn btn-success ml-3" type="submit">
                <span class="svg-icon">
                  <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
                </span>
                Lưu
              </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </b-overlay>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import isInteger from 'lodash/isInteger';
import {
  CALENDAR_TYPES,
  MODAL_TYPE,
  REGEX,
  STUDY_PROGRESS_TYPES,
  SUMMARY_TYPES,
  SUPORT_TYPES,
} from '../../../../core/plugins/constants';
import { GET_LAST_COMMENT } from '../../../../core/services/store/userDashboard.module';
import { roundNumber } from '../../../../core/mixins';
const { mapState, mapActions } = createNamespacedHelpers('userDashboard');
export default {
  name: 'ModalComment',
  props: {
    typeForm: {
      type: String,
      require: true,
    },
    isOpenFromCommentList: {
      type: Boolean,
      default: false,
    },
    commentId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      REGEX,
      types: [
        {
          text: 'Coach đầu vào',
          value: CALENDAR_TYPES.INPUT_RATING,
        },
        {
          text: 'Coach đầu ra',
          value: CALENDAR_TYPES.OUTPUT_RATING,
        },
        {
          text: 'Coach 1 - 1',
          value: CALENDAR_TYPES.COACH_11,
        },
        {
          text: 'Coach 1 - n',
          value: CALENDAR_TYPES.COACH_1N,
        },
        {
          text: 'Bác sĩ đánh giá',
          value: CALENDAR_TYPES.DOCTOR_COMMENT,
        },
      ],
      form: {
        type: null,
        progressType: {
          text: 'Đúng lộ trình',
          value: STUDY_PROGRESS_TYPES.RIGHT_WAY,
        },
        supportType: {
          text: 'Bình thường',
          value: SUPORT_TYPES.NORMAL,
        },

        HBAC: null,
        BMI: this.calculateBMI,
        PointVD: null,
        PointKT: null,
        chieucao: null,
        cannang: null,

        surveyIdChoose: null,
        fitnessTest: true,
        commentDate: this.$moment(new Date()).format('DD/MM/YYYY'),
        coachSection: null,
        comment: null,
        ratingForClient: null,
        conclusion: null,
        clientStory: null,
        target: null,
        plan: null,
        trouble: null,
        sitDownAndStandUp: null,
        guyLeft: null,
        guyRight: null,
        note: null,
        nextActivity: null,
        history: {
          point: null,
          description: null,
        },
        health: {
          point: null,
          description: null,
        },
        abilityCare: {
          point: null,
          description: null,
        },
        difficultCare: {
          point: null,
          description: null,
        },
        mind: {
          point: null,
          description: null,
        },
        innerMotivation: {
          point: null,
          description: null,
        },
        outerMotivation: {
          point: null,
          description: null,
        },
        classify: {
          point: null,
          description: null,
        },
      },
      studyProgress: [
        {
          text: 'Cần cải thiện',
          value: STUDY_PROGRESS_TYPES.NEED_IMPROVE,
        },
        {
          text: 'Có tiến bộ',
          value: STUDY_PROGRESS_TYPES.IMPROVING,
        },
        {
          text: 'Đúng lộ trình',
          value: STUDY_PROGRESS_TYPES.RIGHT_WAY,
        },
        {
          text: 'Tốt',
          value: STUDY_PROGRESS_TYPES.GOOD,
        },
        {
          text: 'Rất tốt',
          value: STUDY_PROGRESS_TYPES.VERY_GOOD,
        },
        {
          text: 'Ngưng học',
          value: STUDY_PROGRESS_TYPES.PENDING,
        },
      ],
      prioritySupport: [
        {
          text: 'Ưu tiên',
          value: SUPORT_TYPES.PREFER,
        },
        {
          text: 'Bình thường',
          value: SUPORT_TYPES.NORMAL,
        },
      ],
      summaryTypes: [
        {
          text: 'Có thể tự duy trì lối sống',
          value: SUMMARY_TYPES.ABLE_MAINTAIN_LIFESTYLE,
        },
        {
          text: 'Mua gói chuyên sâu',
          value: SUMMARY_TYPES.BUY_PACKAGE,
        },
        {
          text: 'Học tiếp gói hiện tại',
          value: SUMMARY_TYPES.CONTINUTE_LEARN,
        },
      ],
      dataCoachSection: null,
      coachSections: [],
      loading: false,
      sections: [],
      totalPoints: 0,
      totalPointsVD:0
    };
  },
  computed: {
    ...mapState(['customerInfo']),
    currentUser() {
      return this.$store.state.auth.user;
    },
    isInputCoach() {
      return this.form.type?.value === CALENDAR_TYPES.INPUT_RATING;
    },
    isCoach1_1() {
      return this.form.type?.value === CALENDAR_TYPES.COACH_11;
    },
    isCoach1_N() {
      return this.form.type?.value === CALENDAR_TYPES.COACH_1N;
    },
    isOutputCoach() {
      return this.form.type?.value === CALENDAR_TYPES.OUTPUT_RATING;
    },
    isRatingDoctor() {
      return this.form.type?.value === CALENDAR_TYPES.DOCTOR_COMMENT;
    },
    modalTitle() {
      switch (this.typeForm) {
        case MODAL_TYPE.CREATE:
          return 'Thêm mới nhận xét';
        case MODAL_TYPE.EDIT:
          return 'Chỉnh sửa nhận xét';

        default:
          return 'Xem chi tiết nhận xét';
      }
    },
    patientId() {
      return this.$route.params.id;
    },
    isCreate() {
      return this.typeForm === MODAL_TYPE.CREATE;
    },
    isDetail() {
      return this.typeForm === MODAL_TYPE.DETAIL;
    },
    BMI: function () {
      return this.chieucao * this.cannang;
    },
  },
  watch: {
    'form.comment'(val) {
      if (!val) return;
      this.$validator.reset({ name: 'comment' });
    },
    'form.ratingForClient'(val) {
      if (!val) return;

      this.$validator.reset({ name: 'rating' });
    },
    'form.nextActivity'(val) {
      if (!val) return;

      this.$validator.reset({ name: 'nextActivity' });
    },
  },

  methods: {
    ...mapActions({ GET_LAST_COMMENT }),
    resetModal() {
      this.resetFormData();
      this.dataCoachSection = null;
      this.coachSections = [];
      this.$emit('reset');
    },
    resetFormData() {
      this.form = {
        type: null,
        progressType: {
          text: 'Đúng lộ trình',
          value: 3,
        },
        supportType: {
          text: 'Bình thường',
          value: 2,
        },
        HBAC: null,
        BMI: null,
        PointVD: null,
        PointKT: null,
        chieucao: null,
        cannang: null,

        commentDate: this.$moment(new Date()).format('DD/MM/YYYY'),
        fitnessTest: true,
        comment: null,
        ratingForClient: null,
        conclusion: null,
        target: null,
        plan: null,
        trouble: null,
        clientStory: null,
        sitDownAndStandUp: null,
        guyLeft: null,
        guyRight: null,
        note: null,
        nextActivity: null,
        history: {
          point: null,
          description: null,
        },
        health: {
          point: null,
          description: null,
        },
        abilityCare: {
          point: null,
          description: null,
        },
        difficultCare: {
          point: null,
          description: null,
        },
        mind: {
          point: null,
          description: null,
        },
        innerMotivation: {
          point: null,
          description: null,
        },
        outerMotivation: {
          point: null,
          description: null,
        },
        classify: {
          point: null,
          description: null,
        },
      };
    },
    async onShowForm() {
      this.loading = true;
      await Promise.all([this.getStudyProgress(), this.getPrepareData()]);
      await this.getCommentDetail();

      this.loading = false;
    },
    async getPrepareData() {
      try {
        const { meta, data, error } = await this.$api.get(
          `/UserDashboard/Calendar-Training-Comment/Prepare/${this.patientId}`,
        );

        if (!meta.success)
          return this.showToastrMessage(error.message, 'error');

        this.dataCoachSection = data;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getStudyProgress() {
      try {
        const { meta, data, error } = await this.$api.get(
          `/UserDashboard/Calendar-Training-Latest-Input/${this.patientId}`,
        );

        if (!meta.success)
          return this.showToastrMessage(error.message, 'error');

        if (this.isCreate) {
          this.form.progressType = this.studyProgress.find(
            (el) => el.value === data.progressType,
          );
          this.form.supportType = this.prioritySupport.find(
            (el) => el.value === data.supportTypes,
          );
        }
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    async getCommentDetail() {
      if (this.isCreate) return;
      try {
        const { meta, data, error } = await this.$api.get(
          `/UserDashboard/Calendar-Training-Comment/${this.commentId}`,
        );

        if (!meta.success)
          return this.showToastrMessage(error.message, 'error');

        const {
          type,
          updateDateTime,
          progressType,
          supportTypes,
          calendarTrainingId,
          clientStory,
          historyPoint,
          history,
          healthPoint,
          health,
          selfCare,
          selfCarePoint,
          struggleWithSelfCarePoint,
          struggleWithSelfCare,
          psychologicalAdjustmentPoint,
          psychologicalAdjustment,
          internalMotivationPoint,
          internalMotivation,
          externalMotivationPoint,
          externalMotivation,
          knowledgePoint,
          knowledge,
          target,
          hbac,
          bmi,
          pointVD,
          pointKT,
          chieucao,
          cannang,

          plan,
          trouble,
          comment,
          finalResult,
          conclusion,
          nextActivity,
          hasPhysicalTest,
          leftBalance,
          rightBalance,
          standUpSitDown,
          noteDiaby,
          coachDate,
        } = data;

        this.form.type = this.types.find((el) => el.value === type);
        this.setCoachSections(this.form.type);

        this.form.progressType = this.studyProgress.find(
          (el) => el.value === progressType,
        );
        this.form.supportType = this.prioritySupport.find(
          (el) => el.value === supportTypes,
        );

        this.form.commentDate = this.convertTimestampToDate(updateDateTime);

        this.form.coachSection = {
          text: this.getCoachSection(coachDate),
          value: calendarTrainingId,
        };

        if (this.isInputCoach || this.isOutputCoach) {
          if (this.isInputCoach) {
            this.form.clientStory = clientStory;
            this.form.target = target;
            this.form.trouble = trouble;
            this.form.plan = plan;
            this.form.HBAC = hbac;
            this.form.BMI = bmi;
            this.form.PointKT = pointKT;
            this.form.PointVD = pointVD;
            this.form.chieucao = chieucao;
            this.form.cannang = cannang;
          } else {
             this.form.HBAC = hbac;
             console.log(hbac);
            // this.form.BMI = bmi;
            // this.form.PointKT = pointKT;
            // this.form.PointVD = pointVD;
            // this.form.chieucao = chieucao;
            // this.form.cannang = cannang;
            this.form.ratingForClient = finalResult;
            this.form.conclusion = this.summaryTypes.find(
              (el) => el.value === conclusion,
            );
          }
          this.form.history = {
            point: historyPoint,
            description: history,
          };
          this.form.health = {
            point: healthPoint,
            description: health,
          };
          this.form.abilityCare = {
            point: selfCarePoint,
            description: selfCare,
          };
          this.form.difficultCare = {
            point: struggleWithSelfCarePoint,
            description: struggleWithSelfCare,
          };
          this.form.mind = {
            point: psychologicalAdjustmentPoint,
            description: psychologicalAdjustment,
          };
          this.form.innerMotivation = {
            point: internalMotivationPoint,
            description: internalMotivation,
          };
          this.form.outerMotivation = {
            point: externalMotivationPoint,
            description: externalMotivation,
          };
          this.form.classify = {
            point: knowledgePoint,
            description: knowledge,
          };
        }

        if (this.isCoach1_1) {
          this.form.nextActivity = nextActivity;
          this.form.fitnessTest = hasPhysicalTest;
          this.form.guyLeft = leftBalance;
          this.form.guyRight = rightBalance;
          this.form.sitDownAndStandUp = standUpSitDown;
        }

        if (this.isRatingDoctor) {
          this.form.note = noteDiaby;
        }

        this.form.comment = comment;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      }
    },
    getCoachSection(date) {
      if (!date) return null;
      return this.convertTimestampToDate(date, 'DD/MM/YYYY HH:mm');
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }

      return null;
    },
    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },
    setCoachSections(selected) {
      switch (selected.value) {
        case CALENDAR_TYPES.COACH_11:
          this.coachSections =
            this.dataCoachSection.calendarTrainingCoach11?.map((el) => ({
              ...el,
              text: `${this.convertTimestampToDate(
                Number(el.text),
                'DD/MM/YYYY HH:mm',
              )} - ${el.calendarName}`,
            })) || [];
          break;
        case CALENDAR_TYPES.COACH_1N:
          this.coachSections =
            this.dataCoachSection.calendarTrainingCoach1N?.map((el) => ({
              ...el,
              text: `${this.convertTimestampToDate(
                Number(el.text),
                'DD/MM/YYYY HH:mm',
              )} - ${el.calendarName}`,
            })) || [];
          break;
        case CALENDAR_TYPES.OUTPUT_RATING:
          this.coachSections =
            this.dataCoachSection.calendarTrainingCoachDauRa?.map((el) => ({
              ...el,
              text: `${this.convertTimestampToDate(
                Number(el.text),
                'DD/MM/YYYY HH:mm',
              )} - ${el.calendarName}`,
            })) || [];
          break;

        default:
          this.coachSections =
            this.dataCoachSection.calendarTrainingCoachDauVao?.map((el) => ({
              ...el,
              text: `${this.convertTimestampToDate(
                Number(el.text),
                'DD/MM/YYYY HH:mm',
              )} - ${el.calendarName}`,
            })) || [];
          break;
      }
    },
    selectRatingType(selected) {
      if (!selected) {
        this.coachSections = [];
      } else {
        this.setCoachSections(selected);
        //    get data to show Index KPIs
        this.chooseSurveyId();
        this.getServeyResult();
        this.form.type = null;
        this.$nextTick(() => {
          this.form.type = selected;
        });
      }
      this.form = {
        ...this.form,
        HBAC: null,
        BMI: null,
        PointVD: null,
        PointKT: null,
        fitnessTest: true,
        comment: null,
        ratingForClient: null,
        conclusion: null,
        target: '<strong><u>MỤC TIÊU THAY ĐỔI LỐI SỐNG & KẾ HOẠCH HÀNH ĐỘNG</u></strong><br><br>1/ Ăn uống theo chế độ ăn lành mạnh:<br><br>2/ Tập luyện và vận động thể chất:<br><br>3/ Đo đường huyết theo đúng lịch khuyến cáo:<br><br>4/ Thực hiện chăm sóc bàn chân: <br><br>5/ Tuân thủ sử dụng thuốc theo toa:<br><br>6/ Ngủ đủ giấc:<br><br>7/ Thực hành thư giãn:<br><br>8/ Thực hành hít thở sâu:<br><br>9/ Thực hành thiền:',
        plan: null,
        trouble: null,
        clientStory: '<strong><u>MỤC TIÊU CHƯƠNG TRÌNH</u></strong><br>HbA1c:<br>Cân nặng:<br>Kiến thức:<br>Khả năng tự chăm sóc:',
        sitDownAndStandUp: null,
        guyLeft: null,
        guyRight: null,
        note: null,
        nextActivity: null,
        history: {
          point: null,
          description: null,
        },
        health: {
          point: null,
          description: null,
        },
        abilityCare: {
          point: null,
          description: null,
        },
        difficultCare: {
          point: null,
          description: null,
        },
        mind: {
          point: null,
          description: null,
        },
        innerMotivation: {
          point: null,
          description: null,
        },
        outerMotivation: {
          point: null,
          description: null,
        },
        classify: {
          point: null,
          description: null,
        },
      };

      this.$validator.reset({ name: 'type' });
    },
    handleValidate() {
      this.$validator.validateAll().then((result) => {
        if (!result) return;

        this.handleSubmit();
      });
    },
    handleSubmit() {
      let payload = {
        patientId: this.customerInfo.id,
        accountId: this.customerInfo.accountId,
        createDatetime: this.convertDateToTimestamp(this.form.commentDate),
        type: this.form.type.value,
        reviewerId: this.currentUser.id,
      };

      if (!this.isRatingDoctor) {
        payload = {
          ...payload,
          progressType: this.form.progressType.value,
          supportTypes: this.form.supportType.value,
          calendarTrainingId: this.form.coachSection.value,
        };
      }

      if (this.isInputCoach || this.isOutputCoach) {
        payload = {
          ...payload,
          history: this.form.history.description,
          historyPoint: this.convertNumber(this.form.history.point),
          health: this.form.health.description,
          healthPoint: this.convertNumber(this.form.health.point),
          selfCare: this.form.abilityCare.description,
          selfCarePoint: this.convertNumber(this.form.abilityCare.point),
          struggleWithSelfCare: this.form.difficultCare.description,
          struggleWithSelfCarePoint: this.convertNumber(
            this.form.difficultCare.point,
          ),
          psychologicalAdjustment: this.form.mind.description,
          psychologicalAdjustmentPoint: this.convertNumber(
            this.form.mind.point,
          ),
          internalMotivation: this.form.innerMotivation.description,
          internalMotivationPoint: this.convertNumber(
            this.form.innerMotivation.point,
          ),
          externalMotivation: this.form.outerMotivation.description,
          externalMotivationPoint: this.convertNumber(
            this.form.outerMotivation.point,
          ),
          knowledge: this.form.classify.description,
          knowledgePoint: this.convertNumber(this.form.classify.point),
        };

        if (this.isInputCoach) {
          payload = {
            ...payload,
            clientStory: this.form.clientStory,
            target: this.form.target,
            plan: this.form.plan,
            trouble: this.form.trouble,
            comment: this.form.comment,

            hbac: this.form.HBAC,
            bmi: this.form.BMI,
            pointVD: this.form.PointVD,
            pointKT: this.form.PointKT,

            chieucao: this.form.chieucao,
            cannang: this.form.cannang,
          };

          return this.submitInputCoach(payload);
        }
        payload = {
          ...payload,
           hbac: this.form.HBAC,
            bmi: this.form.BMI,
            pointVD: this.form.PointVD,
            pointKT: this.form.PointKT,

            chieucao: this.form.chieucao,
            cannang: this.form.cannang,
          finalResult: this.form.ratingForClient,
          conclusion: this.form.conclusion.value,
        };

        return this.submitOutputCoach(payload);
      }

      if (this.isCoach1_N) {
        payload['comment'] = this.form.comment;
        return this.submitCoach1N(payload);
      }

      if (this.isCoach1_1) {
        payload = {
          ...payload,
          nextActivity: this.form.nextActivity,
          comment: this.form.comment,
        };

        if (this.form.fitnessTest) {
          payload = {
            ...payload,
            leftBalance: this.form.guyLeft,
            rightBalance: this.form.guyRight,
            standUpSitDown: this.form.sitDownAndStandUp,
            hasPhysicalTest: this.form.fitnessTest,
          };
        }

        return this.submitCoach11(payload);
      }

      payload = {
        ...payload,
        noteDiaby: this.form.note,
        comment: this.form.comment,
      };

      return this.submitRatingDoctor(payload);
    },
    async submitInputCoach(payload) {
      try {
        this.loading = true;
        let url = '/UserDashboard/Calendar-Training-Comment/CoachDauVao';
        if (!this.isCreate) {
          url = `${url}/${this.commentId}`;
        }
        const { meta, error } = await this.$api[
          `${this.isCreate ? 'post' : 'put'}`
        ](url, payload);
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.showToastrMessage(
          `${this.isCreate ? 'Tạo mới' : 'Chỉnh sửa'} nhận xét thành công`,
        );
        await this.getLastComment();
        this.onClickCancelBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitOutputCoach(payload) {
      try {
        this.loading = true;
        let url = '/UserDashboard/Calendar-Training-Comment/CoachDauRa';
        if (!this.isCreate) {
          url = `${url}/${this.commentId}`;
        }
        const { meta, error } = await this.$api[
          `${this.isCreate ? 'post' : 'put'}`
        ](url, payload);

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.showToastrMessage(
          `${this.isCreate ? 'Tạo mới' : 'Chỉnh sửa'} nhận xét thành công`,
        );
        await this.getLastComment();
        this.onClickCancelBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitCoach11(payload) {
      try {
        this.loading = true;
        let url = '/UserDashboard/Calendar-Training-Comment/Coach1-1';
        if (!this.isCreate) {
          url = `${url}/${this.commentId}`;
        }
        const { meta, error } = await this.$api[
          `${this.isCreate ? 'post' : 'put'}`
        ](url, payload);
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.showToastrMessage(
          `${this.isCreate ? 'Tạo mới' : 'Chỉnh sửa'} nhận xét thành công`,
        );
        await this.getLastComment();
        this.onClickCancelBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitCoach1N(payload) {
      try {
        this.loading = true;
        let url = '/UserDashboard/Calendar-Training-Comment/Coach1-N';
        if (!this.isCreate) {
          url = `${url}/${this.commentId}`;
        }
        const { meta, error } = await this.$api[
          `${this.isCreate ? 'post' : 'put'}`
        ](url, payload);
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.showToastrMessage(
          `${this.isCreate ? 'Tạo mới' : 'Chỉnh sửa'} nhận xét thành công`,
        );
        await this.getLastComment();
        this.onClickCancelBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    async submitRatingDoctor(payload) {
      try {
        this.loading = true;
        let url = '/UserDashboard/Calendar-Training-Comment/Doctor';
        if (!this.isCreate) {
          url = `${url}/${this.commentId}`;
        }
        const { meta, error } = await this.$api[
          `${this.isCreate ? 'post' : 'put'}`
        ](url, payload);
        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }
        this.showToastrMessage(
          `${this.isCreate ? 'Tạo mới' : 'Chỉnh sửa'} nhận xét thành công`,
        );
        await this.getLastComment();
        this.onClickCancelBtn();
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },
    convertNumber(str) {
      if (!str) return null;

      const number = Number(str);

      if (isInteger(number)) return number;

      const leftDecima = str.split('.')[0];
      const rightDecima = str.split('.')[1].slice(0, 1);

      return Number(`${leftDecima}.${rightDecima}`);
    },
    onClickCancelBtn() {
      this.$bvModal.hide('modal-comment');

      if (this.isOpenFromCommentList) {
        this.$bvModal.show('modal-comment-list');
      }
    },
    onCloseForm() {
      if (this.isOpenFromCommentList) {
        this.$bvModal.show('modal-comment-list');
      }
    },
    async getLastComment() {
      await this.GET_LAST_COMMENT(this.patientId);
    },
    calculateBMI() {
      if (isNaN(this.form.cannang || this.form.chieucao)) {
        return (this.form.BMI = 0);
      } else {
        let chieucao = this.form.chieucao / 100;
        this.form.BMI = (this.form.cannang / (chieucao * chieucao)).toFixed(1);
        return this.form.BMI;
      }
    },
    /// KSDV03 , KSDR01
    chooseSurveyId() {
      if (this.isInputCoach) {
        this.surveyIdChoose = null;
        return (this.surveyIdChoose = '0dbd951e-f33e-411b-ff3b-08dba9f86397');
      } else if (this.isOutputCoach) {
        this.surveyIdChoose = null;
        return (this.surveyIdChoose = '98a70576-54f1-49e6-98c0-08da4e82a277');
      } else {
        return (this.surveyIdChoose = null);
      }
    },
    async getServeyResult() {
      try {
        this.loading = true;
        const { meta, data, error } = await this.$api.get(
          '/UserDashboard/Survey-detail',
          {
            params: {
              patientId: this.patientId,
              surveyId: this.surveyIdChoose,
            },
          },
        );
        if (!meta.success) {
          return this.showToastrMessage(
            error.message,
            'Vui lòng chọn loại đầu vào hoặc đầu ra',
          );
        }
        /// KSDV03 , KSDR01
        this.sections = data.sections;
        //HBA1C
        this.form.HBAC = this.sections[0].questions.filter(
          (item) => item.code === 'TTBT14' || item.code === 'TTA00402',
        )[0].result[0].content;
        //CHIEUCAO
        if (this.surveyIdChoose === '0dbd951e-f33e-411b-ff3b-08dba9f86397') {
          this.form.chieucao = this.sections[0].questions.filter(
            (item) => item.code === 'TTBT10',
          )[0].result[0].content;
        } else {
          //    this.loading = true;
          try {
            let chieucao = 0;
            await this.$api
              .get('/UserDashboard/Survey-detail', {
                params: {
                  patientId: this.patientId,
                  surveyId: '0dbd951e-f33e-411b-ff3b-08dba9f86397',
                },
              })
              .then(({ data }) => {
                chieucao = data.sections;

                this.form.chieucao = chieucao[0].questions.filter(
                  (item) => item.code === 'TTBT10',
                )[0].result[0].content;
              });
          } catch {
            this.$toastr.e({
              title: 'Warning!',
              msg: 'Thiếu dữ liệu " Chiều cao ". Vui lòng kiểm tra lại !',
            });
          }
        }

        //CANNANG
        this.form.cannang = this.sections[0].questions.filter(
          (item) => item.code === 'TTBT11' || item.code === 'TTA00401',
        )[0].result[0].content;

        // VANDONG
        if (this.surveyIdChoose === '0dbd951e-f33e-411b-ff3b-08dba9f86397') {
          this.totalPointsVD=0;
          var Point_Kntcs=0;
          var PointVD_edit=0;       
          var PointVD_TCS04=  this.sections[2].questions.filter(
             (item) => item.code === 'TCS04',
           )[0].result[0].answerName;

          for(const item of this.sections[2].questions)
          {
             if(item.result[0].answerName!=null)
            {
              Point_Kntcs+=parseInt(item.result[0].answerName[0])
            }
          }

          var TSC04= this.sections[2].questions.filter(
             (item) => item.code === 'TCS04',
           )[0].result[0].answerName;

            switch (parseInt(TSC04)) {
            case 0:
              PointVD_edit = 7;
              break;
            case 1:
              PointVD_edit = 6;
             break;

            case 3:
              PointVD_edit = 4;
              break;
            case 4:
              PointVD_edit = 3;
              break;
            case 5:
              PointVD_edit = 2;
              break;
            case 6:
              PointVD_edit = 1;
              break;
            case 7:
              PointVD_edit = 0;
              break;
          }
             this.totalPointsVD=roundNumber(((Point_Kntcs - parseInt(PointVD_TCS04)+(PointVD_edit))/10))
            
          
        } else {
          // this.form.PointVD = this.sections[2].questions.filter(
          //   (item) => item.code === 'CSE10102',
          // )[0].result[0].answerName;
          //  this.totalPointsVD=0;
          // for(const item of this.sections[2].questions)
          // {
          //   if(item.result[0].answerName!=null)
          //   {
          //     this.totalPointsVD+=1
          //   }
          // }
        }
        //  KIENTHUC
        if (this.surveyIdChoose === '0dbd951e-f33e-411b-ff3b-08dba9f86397') {
      
          this.totalPoints = 0;
          for (const item of this.sections[1].questions) {
            if (item.result[0].point === 1) {
              this.totalPoints += 1;
            }
          }
             
        } else {
          
          this.totalPoints = 0;
          for (const item of this.sections[1].questions) {  
            if (item.result[0].point === 1) {
              this.totalPoints += 1;
            }
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.form.BMI = this.calculateBMI();
        this.form.PointKT = Math.round((this.totalPoints*10)/23);
         this.form.PointVD= this.totalPointsVD;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.text-gray {
  color: #b5b5c3;
  width: 50%;
}

.infomation {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 20px;
  align-items: center;
  padding-bottom: 30px;
}
</style>

<style lang="scss">
.v-label {
  font-size: 1rem;
  margin-bottom: 0;
  color: #515356 !important;
  margin-left: 10px;
}
.ql-container.ql-snow {
  width: 1044px;
  height: auto;
}
</style>
